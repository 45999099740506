import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { showImg, showToast } from "@/utils/utils";
import { useRouter } from "vue-router";
import { getUserBalance } from "@/api/userInfo";
export default defineComponent({
  name: "my",

  setup() {
    const todos = ref([]);
    const container = ref();
    const showEmailDialog = ref(false);
    const router = useRouter();
    const person = reactive({
      state: {
        age: 0,
        name: "weizhanzhan"
      },
      methods: {
        setAge: age => {
          person.state.age = age;
        }
      }
    });
    const userInfo = reactive({
      userName: "",
      balance: ""
    });
    const animatedBalance = ref(0); // 数字递增动画函数

    const animateValue = (refValue, targetValue, duration) => {
      const startValue = refValue.value;
      const startTime = performance.now();

      const step = currentTime => {
        const progress = Math.min((currentTime - startTime) / duration, 1);
        refValue.value = Math.floor(progress * (targetValue - startValue) + startValue);

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);
    }; // 监听 balance 的变化


    watch(() => userInfo.balance, newBalance => {
      animateValue(animatedBalance, newBalance, 1000); // 1秒内完成动画
    });

    const toDetail = path => {
      router.push(path);
    };

    const cancelDialog = () => {
      showEmailDialog.value = false;
    };

    const confirmDialog = () => {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token"); // 清除本地存储中的用户信息

      showEmailDialog.value = false;
      showToast("Logout successful"); // 显示提示信息

      setTimeout(() => {
        router.push("/login"); // 2秒后跳转到登录页面
      }, 2000); // 延迟2秒
    }; // 从本地存储读取 userInfo 信息


    onMounted(async () => {
      const res = await getUserBalance();
      const storedUserInfo = localStorage.getItem("userInfo");

      if (storedUserInfo) {
        const parsedUserInfo = JSON.parse(storedUserInfo);
        userInfo.userName = parsedUserInfo.userName || "N/A";
        userInfo.balance = res.data;
      }

      console.log(userInfo, "用户个人信息");
    });
    return {
      todos,
      container,
      person,
      userInfo,
      showEmailDialog,
      animatedBalance,
      showImg,
      toDetail,
      cancelDialog,
      confirmDialog,
      animateValue
    };
  }

});